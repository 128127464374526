
  import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

  import Multiselect from 'vue-multiselect'

  @Component({
    components: { Multiselect }
  })
  export default class Banner extends Vue {

  /** PROPERTIES */

    @Prop({ default: false })
    public disabled!: boolean

    @Prop()
    public id!: string

    @Prop({ default: 'name'})
    public label!: string

    @Prop({ default: 15 })
    public maxLength!: number

    @Prop({ default: true })
    public multiple!: boolean

    @Prop({ default: () => new Array<any>() })
    public options!: [any]

    @Prop({ default: 'id' })
    public trackBy!: string

    @VModel({ default: () => new Array<any>() })
    protected model!: [any]

    
    /** PRIVATE METHODS */

    protected truncate(text: string, limit: number) {
      if (text.length <= limit) {
        return text;
      }

      return text.slice(0, limit) + '...'
    }
  }


